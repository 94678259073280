import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { clone } from 'lodash/core';
import { getXTimefromSpecificDate } from '../../../Share/utils/dateUtil';
import history from '../../../Share/utils/history';
import {
  Translate,
  DropdownList,
  TransvoiceTimePickerV2,
  TransvoiceDateRangePicker,
  TransvoiceButton,
  TextField,
  Checkbox,
  ToggleSwitch,
} from '../../../Share/components';
import { cloneDeep } from 'lodash-es';
import { isContactPersonValid } from '../../../Share/utils';
import { TOP_SKILLS } from '../../../Share/constants';
import isEmpty from 'lodash/isEmpty';

const propTypes = {
  onChange: PropTypes.func,
  onSwitchToMultipleOrder: PropTypes.func,
  isSameAbove: PropTypes.bool,
  contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  dateRange: PropTypes.shape({}),
  contactPerson: PropTypes.string,
  profile: PropTypes.shape({}),
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  isSingle: PropTypes.bool,
  onClose: PropTypes.func,
  openAdvanceView: PropTypes.func,
  // RouteLeavingGuardHandler: PropTypes.func,
  isToggleChecked: PropTypes.bool,
  onToggleSwitchChange: PropTypes.func,
};

const defaultProps = {
  onChange: () => {},
  onClose: () => {},
  onSwitchToMultipleOrder: () => {},
  openAdvanceView: () => {},
  isSameAbove: false,
  contactPersons: [],
  services: [],
  dateRange: {},
  contactPerson: '',
  profile: {},
  languages: [],
  isSingle: false,
  // RouteLeavingGuardHandler: () => {},
  isToggleChecked: false,
  onToggleSwitchChange: () => {},
};

class QuickOrderForm extends Component {
  constructor(props) {
    super(props);
    const isTodayIsNotSelected = this.validationDatePicker();
    this.state = {
      isSameAbove: props.isSameAbove,
      showModal: false,
      showCerfiticate: props.showCerfiticate,
      isTodayIsNotSelected,
      isDirty: true,
      filteredLanguages: [],
    };
    this.scrollContainer = React.createRef();
  }
  componentDidMount() {
    // const { RouteLeavingGuardHandler } = this.props;
    // RouteLeavingGuardHandler(true);
  }

  componentDidUpdate() {
    if (
      this.state.filteredLanguages?.length === 0 &&
      this.props.languages?.length > 0
    ) {
      this.setState({
        ...this.state,
        filteredLanguages: this.appendTopSkillsToFront(this.props.languages),
      });
    }
  }

  appendTopSkillsToFront = (languages = []) => {
    const top = languages.filter(l => TOP_SKILLS.includes(l.name));
    return [...top.map(t => ({ ...t, highlight: true })), ...languages];
  };

  validationDatePicker = () => {
    let isTodayIsNotSelected = true;
    const todayDate = moment(new Date()).format('DD/MM/YYYY');
    const selectedDate =
      this.props.dateRange.ranges.startDate.format('DD/MM/YYYY');
    if (selectedDate === todayDate) isTodayIsNotSelected = false;
    return isTodayIsNotSelected;
  };

  handleChange = event => {
    const {
      target: { name, value },
    } = event;
    this.handleAssignmentChange(event);
    this.props.onChange({ [name]: value });
  };

  handleSessionChange = (e, key) => {
    const dateRange = clone(this.props.dateRange); // use clone() to fix object reference issue
    dateRange[key] = e;
    const baseStartTime = dateRange ? dateRange.startTime : null;
    const baseEndTime = dateRange ? dateRange.endTime : null;

    if (key !== 'startTime' && key !== 'endTime') {
      const selectedDate = e.startDate.format('DD/MM/YYYY');
      const todayDate = moment(new Date()).format('DD/MM/YYYY');
      if (selectedDate === todayDate) {
        this.setState({ isTodayIsNotSelected: false });
        e.startDate.add('1', 'days');
        e.endDate.add('1', 'days');
      }
    }

    // update start/end time values of this session
    if (key === 'startTime') {
      const { startTime } = clone(dateRange); // use clone() to fix object reference issue
      const { endTime } = dateRange;

      dateRange.endTime = getXTimefromSpecificDate(
        startTime,
        dateRange.durationDifference,
        'minutes',
      );
    } else if (key === 'endTime') {
      const { endTime } = clone(dateRange); // use clone() to fix object reference issue
      const { startTime } = dateRange;

      const durationDiffInMinutes = moment
        .duration(endTime - startTime)
        .asMinutes();
      dateRange.durationDifference = durationDiffInMinutes;
    }

    const isChangeSerialDuration = true;
    this.props.onChange({ dateRange, isChangeSerialDuration });
  };

  handleSessionDateChange = (e, element) => {
    const { dateRange } = this.props;
    const { key } = dateRange.ranges;
    element.onToggleMenu();
    if (key && e[key]) {
      const { startDate, endDate } = e[key];
      if (startDate && endDate) {
        this.handleSessionChange(
          {
            startDate: moment(startDate),
            endDate: moment(endDate),
            key,
          },
          'ranges',
        );
      }
    }
  };

  handleAssignmentChange = e => {
    const {
      target: { value },
    } = e;

    const { services } = this.props;
    const selectedService = services.filter(
      item => item.ServiceIdentifier === value.value,
    );
    if (selectedService.length > 0) {
      const availableCompetences = selectedService[0].AvailableCompetences.map(
        (item, i) => ({
          name: item.CompetenceLevelName,
          value: item.CompetenceLevelIdentifier,
        }),
      );
      this.setState({ ...this.state, availableCompetences });
    }
  };

  changeContactValue = event => {
    const { contactPersons, onHandleModal } = this.props;
    const { isSameAbove } = this.state;
    if (event.target.value == 'ADD_CONTACTPERSON') {
      onHandleModal();
      return;
    }
    const selectedContactPerson = event.target.value;
    const contactPersonData = {
      contactPerson: selectedContactPerson ? selectedContactPerson.value : '',
      contactNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberMobile
        : '',
      contactDirectNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberDirect
        : '',
      contactEmail: selectedContactPerson ? selectedContactPerson.Email : '',
    };

    this.props.onChange(contactPersonData);
    if (isSameAbove && event.target.value !== 'none') {
      const e = {
        target: {
          value: selectedContactPerson.ContactPersonIdentifier,
        },
      };
      this.changeContactOrderValue(e);
    }

    if (isSameAbove && event.target.value === 'none') {
      this.setState({
        ...this.state,
        isSameAbove: !isSameAbove,
      });
    }
  };

  changeContactOrderValue = event => {
    const { contactPersons, onHandleModal } = this.props;

    const selectedContactPerson = event.target.value;
    const contactPersonData = {
      orderContactPerson: selectedContactPerson
        ? selectedContactPerson.value
        : '',
      orderContactNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberMobile
        : '',
      orderContactDirectNumber: selectedContactPerson
        ? selectedContactPerson.PhoneNumberDirect
        : '',
      orderEmail: selectedContactPerson ? selectedContactPerson.Email : '',
    };
    this.props.onChange(contactPersonData);
  };

  generateMediaType = () => {
    const { services } = this.props;
    const options = [];
    if (services) {
      services.map(service => {
        if (service.DigitalMeetingPlatforms) {
          service.DigitalMeetingPlatforms.map(item => {
            options.push({
              name: item.DigitalMeetingPlatformName,
              value: item.DigitalMeetingPlatformIdentifier,
            });

            return null;
          });
        }
        return null;
      });
    }

    return options;
  };

  generateFieldsOnAssignmentTypeProps = () => {
    const {
      selectedAssignmentTypeData,
      meetingLink,
      phoneNumber,
      meetingPassCode,
      meetingPlatform,
    } = this.props;
    const renderedFields = [];

    if (selectedAssignmentTypeData) {
      selectedAssignmentTypeData.map(item => {
        if (item.DoRequireMeetingUrl) {
          const meetingField = (
            <div className="form-group col-12 px-1">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.videoLink" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="meetingLink"
                maxLength={500}
                value={meetingLink}
                placeholder={Translate({
                  content: 'singleAssignment.videoLinkPlaceHolder',
                })}
                validatorListener={this.validateListnerForMeetingUrl}
                validators={['required', 'urlformat']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.videoLink',
                      }),
                    },
                  }),
                  'Videolänk måste börja med "http://" eller "https://"',
                ]}
              />
            </div>
          );
          const meetingMedia = (
            <div className="form-group col-12 px-1">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.platform" />
              </label>
              <DropdownList
                onChange={this.handleChange}
                name="meetingPlatform"
                value={meetingPlatform.value === null ? null : meetingPlatform}
                className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                options={this.generateMediaType()}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.platform',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          );
          const meetingPassCodeField = (
            <div className="form-group col-12 px-1">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.passCodeHeader" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="meetingPassCode"
                style={{ height: '40px' }}
                maxLength={300}
                value={meetingPassCode}
                placeholder={Translate({
                  content: 'singleAssignment.passCodePlaceHolder',
                })}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.passCodeHeader',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          );

          renderedFields.push(meetingField, meetingMedia, meetingPassCodeField);
        }
        if (item.DoRequireMeetingPhoneNumber) {
          const phoneNumberField = (
            <div className="form-group col-12 px-1">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.telephoneNumber" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="phoneNumber"
                style={{ height: '40px' }}
                maxLength={50}
                value={phoneNumber}
                placeholder={Translate({
                  content: 'singleAssignment.telephonePlaceHolder',
                })}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.telephoneNumber',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          );
          const meetingPassCodeFiled = (
            <div className="form-group col-12 px-1">
              <label htmlFor="other_details">
                <Translate content="singleAssignment.passCodeHeader" />
              </label>
              <TextField
                onChange={this.handleChange}
                className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                name="meetingPassCode"
                style={{ height: '40px' }}
                maxLength={300}
                value={meetingPassCode}
                placeholder={Translate({
                  content: 'singleAssignment.passCodePlaceHolder',
                })}
                validators={['required']}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'singleAssignment.passCodeHeader',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          );
          renderedFields.push(phoneNumberField, meetingPassCodeFiled);
        }
      });
      return renderedFields;
    }
  };

  isSameDayTime = sess => {
    let returnValue = true;
    let session = cloneDeep(sess);

    const beginningTime = moment({
      h: session.startTime.hours(),
      m: session.startTime.minutes(),
    });
    const endTime = moment({
      h: session.endTime.hours(),
      m: session.endTime.minutes(),
    });
    session.ranges.startDate = session.ranges.startDate.clone();
    session.ranges.endDate = session.ranges.endDate.clone();
    if (
      (beginningTime.isAfter(endTime) || endTime.isSame(beginningTime)) &&
      session.ranges.endDate.isSame(session.ranges.startDate, 'day')
    ) {
      returnValue = false;
    }
    return returnValue;
  };

  handleLanguageFilter = ({ filter }) => {
    const { value } = filter;
    let filtered;
    if (value) {
      filtered = this.props.languages.filter(l =>
        l.name.toLowerCase().includes(value.toLowerCase()),
      );
    } else {
      filtered = this.appendTopSkillsToFront(this.props.languages);
    }
    this.setState({
      ...this.state,
      filteredLanguages: filtered,
    });
  };

  render() {
    const {
      dateRange,
      language,
      ContactPersonUponInterpretationRequiresEmail,
      contactPerson,
      contactEmail,
      contactDirectNumber,
      contactNumber,
      ContactPersonOrdererRequiresEmail,
      orderContactPerson,
      orderEmail,
      orderContactDirectNumber,
      orderContactNumber,
      languages,
      alternativeLanguages,
      contactPersons,
      services,
      typeOfAssignment,
      assignmentTypeName,
      onClose,
      onSwitchToMultipleOrder,
      openAdvanceView,
      onHandleModal,
      selectedAssignmentTypeData,
      phoneNumber,
      isEdit,
      OrderNumber,
      isAltLanguageAdded,
      addAltLanguage,
      alternativeLanguage,
      alternativeServiceTypes,
      alternativeTypeOfAssignment,
      isAltServicesAdded,
      addAltServiceType,
      defaultDoAllowAlternativeService,
      alternativeServiceCheck,
      isToggleChecked,
      onToggleSwitchChange,
      clientTelephoneNo,
      showAdditionalService,
    } = this.props;

    const { isSameAbove, isDirty, filteredLanguages } = this.state;
    const assignmentTypes = [];
    if (services) {
      services.map(item => {
        assignmentTypes.push({
          name: item.ServiceName,
          value: item.ServiceIdentifier,
        });
        return null;
      });
    }

    const getValuefromList = (filterText, list) => {
      if (filterText === null || filterText === '') return null;
      const filteredItems = list.filter(x => x.value === filterText);

      return filteredItems ? filteredItems[0] : null;
    };
    const isContactPersonUponInterpretationValid =
      !contactPerson ||
      contactPerson == '' ||
      isContactPersonValid(
        ContactPersonUponInterpretationRequiresEmail,
        contactPerson,
        contactPersons,
      );

    const isContactPersonOrdererValid =
      !orderContactPerson ||
      orderContactPerson == '' ||
      isContactPersonValid(
        ContactPersonOrdererRequiresEmail,
        orderContactPerson,
        contactPersons,
      );
    return (
      <React.Fragment>
        <div className="quick-order-cont side">
          {/* <Scrollbars
            ref={this.scrollContainer}
            className="tv-quick-order__scroll-container"
          > */}
          <div className="form-group col-12  px-1">
            <label>
              <Translate content="singleAssignment.selectDatePeriod" />
            </label>
            <div className=" tv-single-assign-form__input">
              <TransvoiceDateRangePicker
                ranges={dateRange.ranges}
                onChange={this.handleSessionDateChange}
                displayFormat="YYYY-MM-DD"
              />
            </div>
          </div>
          <div className="form-row col-12 px-1 justify-content-between mx-0 w-100">
            <div className="form-group col-12 col-md-5  px-0">
              <label>
                <Translate content="singleAssignment.startTime" />
              </label>
              <TransvoiceTimePickerV2
                defaultValue={dateRange.startTime}
                startFromDayBegin
                handleValueChange={e =>
                  this.handleSessionChange(e, 'startTime')
                }
              />
            </div>
            <div className="form-group col-12 col-md-5  px-0">
              <label>
                <Translate content="singleAssignment.endTime" />
              </label>
              <TransvoiceTimePickerV2
                defaultValue={dateRange.endTime}
                durationCalculate={dateRange.startTime}
                allowNextDay
                isNotRanged={false}
                handleValueChange={e => this.handleSessionChange(e, 'endTime')}
              />
              {!this.isSameDayTime(dateRange) && (
                <span className="tv-form__warning">
                  <i className="fa fa-exclamation-circle mr-1"></i>Nästa dag
                </span>
              )}
            </div>
          </div>
          <div className="form-group col-12 px-1 ">
            <label htmlFor="language">
              <Translate content="singleAssignment.language" />
            </label>

            <DropdownList
              onChange={this.handleChange}
              name="language"
              required
              value={getValuefromList(language.value, languages)}
              className="ellipsis pl-3 py-1 tv-single-assign-form__control"
              options={filteredLanguages}
              validators={['required']}
              errorMessages={[
                Translate({
                  content: 'validator.isRequired',
                  params: {
                    name: Translate({
                      content: 'singleAssignment.language',
                    }),
                  },
                }),
              ]}
              popupHeight={280}
              filterable
              onFilterChange={this.handleLanguageFilter}
            />
          </div>
          {!isAltLanguageAdded && alternativeLanguages.length > 0 && (
            <div className="links-add-container">
              <div
                className="links-add links-add-secondary"
                onClick={() => addAltLanguage(true)}
              >
                + Lägg till alternativt språk
              </div>
            </div>
          )}
          {isAltLanguageAdded && alternativeLanguages.length > 0 && (
            <>
              <div className="form-group col-12 px-1 ">
                <label htmlFor="alternativeLanguage">Alternativt språk</label>

                <DropdownList
                  onChange={this.handleChange}
                  name="alternativeLanguage"
                  required
                  value={getValuefromList(alternativeLanguage.value, languages)}
                  className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                  options={alternativeLanguages}
                  validators={['required']}
                  errorMessages={['Alternativt språk är obligatoriskt']}
                />
              </div>
              <div className="links-add-container">
                <div
                  className="links-add links-add-secondary"
                  onClick={() => addAltLanguage(false)}
                >
                  - Ta bort alternativt språk
                </div>
              </div>
            </>
          )}
          <div className="form-group col-12 px-1 ">
            <label htmlFor="typeOfAssignment">
              <Translate content="singleAssignment.typeOfAssignment" />
            </label>
            <DropdownList
              className="ellipsis pl-3 py-1 tv-single-assign-form__control"
              name="typeOfAssignment"
              options={assignmentTypes}
              value={getValuefromList(typeOfAssignment.value, assignmentTypes)}
              onChange={this.handleChange}
              validators={['required']}
              errorMessages={[
                Translate({
                  content: 'validator.isRequired',
                  params: {
                    name: Translate({
                      content: 'singleAssignment.typeOfAssignment',
                    }),
                  },
                }),
              ]}
              // disabled={
              //   isEdit &&
              //   alternativeTypeOfAssignment &&
              //   alternativeTypeOfAssignment.value &&
              //   true
              // }
            />
          </div>
          {alternativeServiceTypes.length > 0 && (
            <Checkbox
              label={Translate({
                content: 'singleAssignment.telephoneTalkingAlternative',
              })}
              value="altService"
              onClick={alternativeServiceCheck}
              // handleChange={this.handleChange}
              defaultChecked={
                isEdit && alternativeTypeOfAssignment.value !== null
                  ? true
                  : defaultDoAllowAlternativeService
              }
              disabled={isEdit}
              className="label--altService"
              textColor="#74737B"
              labelColor="#F7F9FF"
            />
          )}
          {/* {!isAltServicesAdded && alternativeServiceTypes.length > 0 && (
            <div className="links-add-container">
              <div
                className="links-add links-add-secondary"
                onClick={() => addAltServiceType(true)}
              >
                + Lägg till alternativt språk typ av tolk
              </div>
            </div>
          )} */}
          {/* {isAltServicesAdded && alternativeServiceTypes.length > 0 && (
            <>
              <div className="form-group col-12 px-1 ">
                <label htmlFor="alternativeTypeOfAssignment">
                  <Translate content="singleAssignment.alternativeTypeOfAssignment" />
                </label>
                <DropdownList
                  name="alternativeTypeOfAssignment"
                  options={alternativeServiceTypes}
                  value={getValuefromList(
                    alternativeTypeOfAssignment.value,
                    assignmentTypes,
                  )}
                  onChange={this.handleChange}
                  validators={['required']}
                  errorMessages={[
                    Translate({
                      content: 'validator.isRequired',
                      params: {
                        name: Translate({
                          content:
                            'singleAssignment.alternativeTypeOfAssignment',
                        }),
                      },
                    }),
                  ]}
                  className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                  disabled={isEdit && true}
                />
              </div>
              {!isEdit && (
                <div className="links-add-container">
                  <div
                    className="links-add links-add-secondary"
                    onClick={() => addAltServiceType(false)}
                  >
                    - Ta bort alternativt typ av tolk
                  </div>
                </div>
              )}
            </>
          )} */}
          {this.generateFieldsOnAssignmentTypeProps()}
          <div
            className={`form-group col-12 px-1 ${
              isContactPersonUponInterpretationValid ? '' : 'mb-5'
            }`}
          >
            <label htmlFor="contactPerson">
              <Translate content="singleAssignment.contactPerson" />
            </label>
            <DropdownList
              className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                isContactPersonUponInterpretationValid
                  ? ''
                  : 'tv-form__control--error'
              }`}
              name="contactPerson"
              options={contactPersons}
              value={getValuefromList(contactPerson, contactPersons)}
              required
              onChange={e => {
                this.changeContactValue(e);
              }}
              validators={['required']}
              errorMessages={[
                Translate({
                  content: 'validator.isRequired',
                  params: {
                    name: Translate({
                      content: 'singleAssignment.contactPerson',
                    }),
                  },
                }),
              ]}
              footer={
                <span
                  onClick={e => {
                    e.stopPropagation();
                    onHandleModal(
                      ContactPersonUponInterpretationRequiresEmail,
                      'ContactPersonUponInterpretation',
                    );
                  }}
                  className="tv-dropdown__footer-conactperson-add"
                >
                  + lägg till tolkanvändare
                </span>
              }
            />
            {!isContactPersonUponInterpretationValid && (
              <span class="tv-form__error text-justify">
                E-post krävs.
                <span
                  onClick={e => {
                    e.stopPropagation();
                    onHandleModal(
                      ContactPersonUponInterpretationRequiresEmail,
                      'ContactPersonUponInterpretation',
                      contactPerson,
                    );
                  }}
                  className="contact-person-email-edit"
                >
                  + Lägg till här
                </span>
              </span>
            )}
          </div>
          <div className="form-group col-12 px-1 px-1">
            <label htmlFor="orderContactPerson">
              <Translate content="singleAssignment.contactOrderPerson" />
            </label>

            <DropdownList
              className={`ellipsis pl-3 py-1 tv-single-assign-form__control ${
                isContactPersonOrdererValid ? '' : 'tv-form__control--error'
              }`}
              name="orderContactPerson"
              options={contactPersons}
              footer={
                <span
                  onClick={e => {
                    e.stopPropagation();
                    onHandleModal(
                      ContactPersonOrdererRequiresEmail,
                      'ContactPersonOrderer',
                    );
                  }}
                  className="tv-dropdown__footer-conactperson-add"
                >
                  + lägg till tolkanvändare
                </span>
              }
              value={getValuefromList(orderContactPerson, contactPersons)}
              required
              onChange={(e, v) => {
                this.changeContactOrderValue(e);
              }}
              validators={['required']}
              errorMessages={[
                Translate({
                  content: 'validator.isRequired',
                  params: {
                    name: Translate({
                      content: 'singleAssignment.orderContactPerson',
                    }),
                  },
                }),
              ]}
            />
            {!isContactPersonOrdererValid && (
              <span class="tv-form__error text-justify">
                E-post krävs.
                <span
                  onClick={e => {
                    e.stopPropagation();
                    onHandleModal(
                      ContactPersonOrdererRequiresEmail,
                      'ContactPersonOrderer',
                      orderContactPerson,
                    );
                  }}
                  className="contact-person-email-edit"
                >
                  + Lägg till här
                </span>
              </span>
            )}
          </div>
          {/* </Scrollbars> */}
          <div className="d-flex flex-column tk-assignment-creation__footer">
            <div className="ml-2 mt-2">
              <div className="links-add" onClick={openAdvanceView}>
                {!isEdit
                  ? '+ Lägg till krav'
                  : `Ändra krav ${OrderNumber || ''}`}
              </div>
            </div>
            {showAdditionalService && (
              <>
                <div className="reminder-sms__container-text px-2 pt-3 w-100">
                  Genom att aktivera funktionen nedan har beställande enhet fått
                  klientens medgivande att Transvoice hanterar dennes
                  telefonnummer.
                </div>
                <div className="reminder-sms__container px-2">
                  <div className="reminder-sms__container-text">
                    Skicka ett påminnelse-sms till klientens 48h innan bokad tid.
                  </div>
                  <ToggleSwitch
                    isChecked={isToggleChecked}
                    onToggleSwitchChange={onToggleSwitchChange}
                  />
                </div>
                {isToggleChecked && (
                  <div className="form-group col-12 px-1 pt-2">
                    <label htmlFor="clientTeleNumber">
                      <Translate content="singleAssignment.telephoneNo" />
                    </label>
                    <TextField
                      onChange={this.handleChange}
                      className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                      name="clientTelephoneNo"
                      maxLength={300}
                      value={clientTelephoneNo}
                      placeholder="070-1740605"
                    />
                  </div>
                )}
              </>
            )}

            <div className="tv-workassignment__detail-buttons justify-content-between tv-display-flex">
              <TransvoiceButton
                type="secondary"
                buttonType="submit"
                className="w-50  ml-1"
                text={Translate({
                  content: 'general.continue',
                })}
              />
              <TransvoiceButton
                type="line-primary"
                buttonType="button"
                className="w-50"
                text={Translate({
                  content: 'general.close',
                })}
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

QuickOrderForm.propTypes = propTypes;
QuickOrderForm.defaultProps = defaultProps;

export default QuickOrderForm;
