import React, { Component } from "react";
import PropTypes from "prop-types";
import { ValidatorForm } from "react-form-validator-core";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty, isObject, clone } from "lodash/core";
import { unionWith, isEqual } from "lodash";
import { Prompt } from "react-router";
import { onUpdateNewAssignmentsTemp, createNewOrder } from "../../actions";
import SingleAssignmentForm from "./SingleAssignmentForm";
import ContactPersonModal from "./ContactPersonModal";

import {
    Translate,
    TransvoiceButton,
    RouteLeavingGuard,
} from "../../../Share/components";
import { fetchContactPersons, fetchServices } from "../../actions/user";
import {
    createDefaultAssignment,
    createDefaultContactPerson,
    generateUniqueId,
} from "../../../Share/utils";
import history from "../../../Share/utils/history";
import { SINGLE_FORM } from "../../../Share/constants";
import moment from "moment";
import { toast } from "react-toastify";

const propTypes = {
    onSubmitAssignment: PropTypes.func,
    assignment: PropTypes.arrayOf(PropTypes.shape({})),
    skills: PropTypes.arrayOf(PropTypes.shape({})),
    services: PropTypes.arrayOf(PropTypes.shape({})),
    profile: PropTypes.shape({}),
    languages: PropTypes.arrayOf(PropTypes.shape({})),
    contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
    executeGetContactPersons: PropTypes.func,
    executeGetServices: PropTypes.func,
    onCreateNewOrder: PropTypes.func,
    isDirty: PropTypes.bool,
};

const defaultProps = {
    onSubmitAssignment: () => {},
    assignment: {},
    skills: [],
    services: [],
    contactPersons: [],
    profile: {},
    languages: [],
    executeGetContactPersons: () => {},
    executeGetServices: () => {},
    onCreateNewOrder: () => {},
    isDirty: true,
};

class SingleAssignment extends Component {
    constructor(props) {
        super(props);
        const {
            assignment,
            skills,
            services,
            contactPersons,
            profile,
            isDirty,
        } = props;
        const prevPath = props.location.state
            ? props.location.state.prevPath
            : "";

        if (!isEmpty(assignment) && isObject(assignment)) {
            this.state = {
                assignment: {
                    ...assignment,
                },
                contactPerson: createDefaultContactPerson(),
                showModal: false,
                submitDirect: false,
                isDirty: true,
                contactPersonEmailRequired: false,
            };
        } else {
            this.state = {
                assignment: {
                    ...createDefaultAssignment(
                        SINGLE_FORM,
                        skills,
                        services,
                        contactPersons,
                        profile
                    ),
                },
                contactPerson: createDefaultContactPerson(),
                showModal: false,
                submitDirect: false,
                isDirty: true,
                contactPersonEmailRequired: false,
            };
        }
    }

    componentDidMount() {
        const { assignment } = this.state;
        const {
            executeGetContactPersons,
            executeGetServices,
            languages,
        } = this.props;
        executeGetContactPersons();
        
        var startDate = moment(assignment.dateRange.ranges.startDate);
        var endDate = moment(assignment.dateRange.ranges.endDate);
        const { startTime, endTime } = assignment.dateRange;
        const { language } = assignment;
        

        startDate.set({
            h: startTime.hours(),
            m: startTime.minutes()
        });
        
        endDate.set({ h: endTime.hours(), m: endTime.minutes() });

        // Checking the time - is from next day or current day
        var beginningTime = moment({
            h: startTime.hours(),
            m: startTime.minutes(),
        });
        var endingTime = moment({
            h: endTime.hours(),
            m: endTime.minutes(),
        });

        if (endingTime.isBefore(beginningTime)) {
            var endDateHour = endDate.hours();
            var endDateMinute = endDate.minutes();
            endDate = moment(startDate);
            endDate.hours(endDateHour).minutes(endDateMinute);
            endDate.add(1, "day");
        } else if (endingTime.isSame(beginningTime)) {
            var endDateHour = endDate.hours();
            var endDateMinute = endDate.minutes();
            endDate = moment(startDate);
            endDate.hours(endDateHour).minutes(endDateMinute);
            endDate.add(1, "day");
        } else {
            var endDateHour = endDate.hours();
            var endDateMinute = endDate.minutes();
            endDate = moment(clone(startDate));
            endDate.hours(endDateHour).minutes(endDateMinute);
        }


        const callbackUpdateServices = (allowSelectServices, _assignment) => {
            this.setState({
                assignment: {
                    ...this.state.assignment,
                    services: allowSelectServices,
                    typeOfAssignment:
                        _assignment.typeOfAssignment !== "" ||
                        !_assignment.typeOfAssignment
                            ? _assignment.typeOfAssignment
                            : allowSelectServices[0].ServiceIdentifier,
                },
            });
        };

        const params = {
            language: assignment.language
                ? assignment.language == "-" || assignment.language == ""
                    ? languages[0].value
                    : assignment.language
                : languages[0].value,
            startTime: startDate.format("YYYY-MM-DD HH:mm:ss"),
            endTime:endDate.format("YYYY-MM-DD HH:mm:ss"),
            assignment,
        };

        executeGetServices(params, callbackUpdateServices);

        ValidatorForm.addValidationRule("checkNumLength", (value, number) => {
            if (value.toString().length !== Number(number)) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule("alwaysError", (value, number) => {
            return false;
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
            if (prevState.assignment.services && nextProps.services &&
                (prevState.assignment.services.length !== nextProps.services.length)
            ) {
                return {
                    assignment: {
                        ...prevState.assignment,
                    },
                };
            }

        if (
            prevState.assignment.contactPersons.length !==
            nextProps.contactPersons.length
        ) {
            return {
                assignment: {
                    ...prevState.assignment,
                    contactPersons: unionWith(
                        prevState.assignment.contactPersons,
                        nextProps.contactPersons,
                        isEqual
                    ),
                },
            };
        }
        if (isEmpty(prevState.assignment.profile)) {
            if (
                nextProps.profile.customerIdentifier !== "" &&
                nextProps.profile.customerNumber !== "" &&
                !isEmpty(nextProps.profile.customerAddress)
            ) {
                return {
                    assignment: {
                        ...prevState.assignment,
                        profile: nextProps.profile || {},
                        addressLine:
                            nextProps.profile.customerAddress.AddressRow1 || "",
                        city: nextProps.profile.customerAddress.City || "",
                        district:
                            nextProps.profile.customerAddress.CareOf || "",
                        postalCode: nextProps.profile.customerAddress.PostalCode.toString(),
                        orderCompanyName: nextProps.profile.customerName,
                        orderLocation: `${
                            !nextProps.profile.customerAddress.CareOf
                                ? ""
                                : `${nextProps.profile.customerAddress.CareOf}, `
                        }${nextProps.profile.customerAddress.AddressRow1}, ${
                            nextProps.profile.customerAddress.PostalCode
                        }, ${nextProps.profile.customerAddress.City}`,
                    },
                };
            }
        }
        return null;
    }

    ConvergeValidate = () => {
        const { assignment } = this.state;
        const { profile } = this.props;
        let isValid = true;
        if (
            !profile.ContactPersonUponInterpretationRequiresEmail &&
            assignment.contactPerson &&
            [
                assignment.contactNumber,
                assignment.contactEmail,
                assignment.contactDirectNumber,
            ]
                .join("")
                .trim() === ""
        )
            isValid = false;
        if (
            !profile.ContactPersonOrdererRequiresEmail &&
            assignment.orderContactPerson &&
            [
                assignment.orderContactNumber,
                assignment.orderContactDirectNumber,
                assignment.orderEmail,
            ]
                .join("")
                .trim() === ""
        )
            isValid = false;

        const selectedDate = assignment.dateRange.ranges.startDate.format("DD/MM/YYYY");
        const todayDate = moment(new Date()).format("DD/MM/YYYY");
        if (selectedDate === todayDate) isValid = false;

        return isValid;
    };

    handleSubmit = () => {
        this.setState({ isDirty: false }, () => {
            const { onSubmitAssignment } = this.props;
            const { submitDirect, assignment } = this.state;
            if (this.ConvergeValidate()) {
                onSubmitAssignment(this.state);

                if (submitDirect) {
                    this.props.onCreateNewOrder(assignment);
                } else {
                    history.push("/preview-assignment");
                }
            }
        });
    };
    handleFormError = (e) => {
        ;
        if (e.length > 0) {
            e[0].input.scrollIntoView({ behavior: "smooth" });
        }
    };
    handleChange = (obj) => {
        const { assignment } = this.state;
        const { executeGetServices, languages } = this.props;

        const isChangeTypeOfAssignment =
            obj.typeOfAssignment !== null &&
            obj.typeOfAssignment !== undefined &&
            obj.typeOfAssignment !== "";
        const genderValue = obj && obj.genderValue ? obj.genderValue : "";

        const isChangeContactNumber =
            obj.contactNumber !== null && obj.contactNumber !== undefined;
        const isChangeContactDirectNumber =
            obj.contactDirectNumber !== null &&
            obj.contactDirectNumber !== undefined;
        const isChangeContactEmail =
            obj.contactEmail !== null && obj.contactEmail !== undefined;
        const isChangeContactPerson =
            obj.contactPerson !== null && obj.contactPerson !== undefined;
        const isChangeContactPersonData =
            isChangeContactPerson ||
            isChangeContactNumber ||
            isChangeContactDirectNumber ||
            isChangeContactEmail;

        const isChangeSessions =
            obj.dateRange !== null && obj.dateRange !== undefined;
        const isChangeOrderGroupRequestData =
            obj.OrderGroupRequestData !== null &&
            obj.OrderGroupRequestData !== undefined;

        const isChangeOrderContactPersonNumber =
            obj.orderContactNumber !== null &&
            obj.orderContactNumber !== undefined;
        const isChangeOrderContactDirectNumber =
            obj.orderContactDirectNumber !== null &&
            obj.orderContactDirectNumber !== undefined;
        const isChangeOrderEmail =
            obj.orderEmail !== null && obj.orderEmail !== undefined;
        const isChangeOrderContactPerson =
            obj.orderContactPerson !== null &&
            obj.orderContactPerson !== undefined;
        const isChangeOrderContactPersonData =
            isChangeOrderContactPerson ||
            isChangeOrderContactDirectNumber ||
            isChangeOrderEmail ||
            isChangeOrderContactPersonNumber;

        const isChangeLanguage =
            obj.language !== null &&
            obj.language !== undefined &&
            obj.language !== "";
        
        if (isChangeLanguage || obj.isChangeSerialDuration) {
            var startDate = moment(assignment.dateRange.ranges.startDate);
            var endDate = moment(assignment.dateRange.ranges.endDate);
            const { startTime, endTime } = assignment.dateRange;
            startDate.set({
                hour: startTime.hours(),
                minute: startTime.minutes(),
            });
            endDate.set({ hour: endTime.hours(), minute: endTime.minutes() });

            startDate.set({
                hour: startTime.hours(),
                minute: startTime.minutes(),
            });
            endDate.set({ hour: endTime.hours(), minute: endTime.minutes() });
            startDate.set({
                hour: startTime.hours(),
                minute: startTime.minutes(),
            });

            endDate.set({ hour: endTime.hours(), minute: endTime.minutes() });

            // Checking the time - is from next day or current day
            var beginningTime = moment({
                h: startTime.hours(),
                s: startTime.minutes(),
            });
            var endingTime = moment({
                h: endTime.hours(),
                s: endTime.minutes(),
            });

            if (endingTime.isBefore(beginningTime)) {
                var endDateHour = endDate.hours();
                var endDateMinute = endDate.minutes();
                endDate = moment(startDate);
                endDate.hours(endDateHour).minutes(endDateMinute);
                endDate.add(1, "day");
            } else if (endingTime.isSame(beginningTime)) {
                var endDateHour = endDate.hours()
                var endDateMinute = endDate.minutes()
                endDate = moment(startDate);
                endDate.hours(endDateHour).minutes(endDateMinute);
                endDate.add(1, "day");
            }else{
                var endDateHour = endDate.hours();
                var endDateMinute = endDate.minutes();
                endDate = moment(startDate);
                endDate.hours(endDateHour).minutes(endDateMinute);
            }

            const callbackUpdateServices = (allowSelectServices) => {
                

                let typeOfAssignment =
                    allowSelectServices != null
                        ? allowSelectServices[0].ServiceIdentifier
                        : "";

                if (allowSelectServices != null) {
                    allowSelectServices.forEach((allowSelect) => {
                        if (
                            allowSelect.ServiceIdentifier ===
                            assignment.typeOfAssignment
                        ) {
                            // keep the same type of Assignment
                            typeOfAssignment = assignment.typeOfAssignment;
                        }
                    });
                } else {
                    allowSelectServices = [];
                }

                this.setState({
                    assignment: {
                        ...this.state.assignment,
                        ...obj,
                        services: allowSelectServices,
                        typeOfAssignment,
                        certificationLevels: undefined,
                    },
                });
            };

            const params = {
                language: obj.language
                    ? obj.language == "-"
                        ? languages[0].value
                        : obj.language
                    : assignment.language,
                startTime: startDate.format("YYYY-MM-DD HH:mm:ss"),
                endTime: endDate.format("YYYY-MM-DD HH:mm:ss"),
            };
            executeGetServices(params, callbackUpdateServices);
        } else if (isChangeTypeOfAssignment) {
            this.setState({
                assignment: {
                    ...this.state.assignment,
                    ...obj,
                },
            });
        } else if (
            isChangeContactPersonData ||
            isChangeOrderContactPersonData
        ) {
            const isSameContactPerson =
                assignment.orderContactPerson === assignment.contactPerson;
            const isChangeContactPersonInputs =
                !isChangeContactPerson && !isChangeOrderContactPerson;
            if (isSameContactPerson && isChangeContactPersonInputs) {
                const contactPersonValue = { ...obj };
                [
                    {
                        contactPersonField: "contactNumber",
                        orderContactPersonField: "orderContactNumber",
                    },
                    {
                        contactPersonField: "contactDirectNumber",
                        orderContactPersonField: "orderContactDirectNumber",
                    },
                    {
                        contactPersonField: "contactEmail",
                        orderContactPersonField: "orderEmail",
                    },
                ].forEach((i) => {
                    if (
                        obj[i.contactPersonField] !== null &&
                        obj[i.contactPersonField] !== undefined
                    ) {
                        contactPersonValue[i.orderContactPersonField] =
                            obj[i.contactPersonField];
                    } else if (
                        obj[i.orderContactPersonField] !== null &&
                        obj[i.orderContactPersonField] !== undefined
                    ) {
                        contactPersonValue[i.contactPersonField] =
                            obj[i.orderContactPersonField];
                    }
                });
                this.setState({
                    assignment: {
                        ...this.state.assignment,
                        ...contactPersonValue,
                    },
                });
            } else {
                this.setState({
                    assignment: {
                        ...this.state.assignment,
                        ...obj,
                    },
                });
            }
        } else if (isChangeOrderGroupRequestData) {
            const { OrderGroupRequestData } = obj;
            if (
                OrderGroupRequestData.DoRequireSameResourceForAllWorkAssignments
            ) {
                this.setState({
                    assignment: {
                        ...this.state.assignment,
                        ...obj,
                    },
                });
            } else {
                this.setState({
                    assignment: {
                        ...this.state.assignment,
                        ...obj,
                        OrderGroupRequestData: null,
                    },
                });
            }
        } else if (isChangeSessions) {
            if (
                obj.OrderGroupRequestData !== null &&
                obj.OrderGroupRequestData !== undefined
            ) {
                this.setState({
                    assignment: {
                        ...this.state.assignment,
                        ...obj,
                        OrderGroupRequestData: null,
                    },
                });
            } else {
                this.setState({
                    assignment: {
                        ...this.state.assignment,
                        ...obj,
                    },
                });
            }
        } else {
            this.setState({
                assignment: {
                    ...this.state.assignment,
                    ...obj,
                    DoRequireCorrectGender: genderValue !== "0",
                },
            });
        }
    };
    handleModal = (value) => {
        const { showModal, contactPersonEmailRequired } = this.state;
        this.setState({ contactPersonEmailRequired: value });
        this.setState({ showModal: !showModal });
    };

    handleConfirm = () => {
        const { contactPerson } = this.state;
        const contactPersons = clone(this.state.assignment.contactPersons);
        contactPerson.value = `newPerson-${generateUniqueId()}`;
        contactPerson.name = `${contactPerson.FirstName} ${
            contactPerson.LastName
        } ${contactPerson.Title ? `- ${contactPerson.Title}` : ""}`;
        contactPersons.push(contactPerson);
        this.setState({
            assignment: {
                ...this.state.assignment,
                contactPersons,
            },
            contactPerson: createDefaultContactPerson(),
        });
    };

    handleChangeContactPerson = (obj) => {
        const { contactPerson } = this.state;
        this.setState({
            contactPerson: {
                ...contactPerson,
                ...obj,
            },
        });
    };

    handleValidatorListener = () => {};

    render() {
        const {
            assignment,
            contactPerson,
            showModal,
            contactPersonEmailRequired,
        } = this.state;
        const { languages, profile } = this.props;
        const { isDirty } = this.state;
        return (
            <React.Fragment>
                <h2 className="tv-single-assign-form__title">
                    <Translate content="singleAssignment.createNewAssignment" />
                </h2>
                <RouteLeavingGuard
                    when={isDirty}
                    navigate={(path) => history.push(path)}
                    shouldBlockNavigation={(location) => {
                        if (isDirty) {
                            return true;
                        }
                        return false;
                    }}
                />

                <ContactPersonModal
                    emailRequired={contactPersonEmailRequired}
                    {...contactPerson}
                    showModal={showModal}
                    onChange={this.handleChangeContactPerson}
                    onHandleModal={this.handleModal}
                    onHandleConfirm={this.handleConfirm}
                />
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    onError={this.handleFormError}
                >
                    <SingleAssignmentForm
                        {...assignment}
                        validatorListener={this.handleValidatorListener}
                        onChange={this.handleChange}
                        onHandleModal={this.handleModal}
                        languages={languages}
                        createdFrom="singleAssignment"
                        ContactPersonOrdererRequiresEmail={
                            profile.ContactPersonOrdererRequiresEmail
                        }
                        ContactPersonUponInterpretationRequiresEmail={
                            profile.ContactPersonUponInterpretationRequiresEmail
                        }
                        isSingle
                    />

                    <div className="tv-single-assign-form__action-container tv-display-flex">
                        <TransvoiceButton
                            type="primary"
                            buttonType="submit"
                            className="tv-single-assign-form__button-preview"
                            text={Translate({
                                content: "singleAssignment.previewAssignment",
                            })}
                        />
                        <TransvoiceButton
                            type="primary"
                            buttonType="submit"
                            className="tv-single-assign-form__button-preview"
                            text={Translate({
                                content: "previewAssignment.confirmNow",
                            })}
                            onClick={() => {
                                this.setState({ submitDirect: true });
                            }}
                        />
                    </div>
                </ValidatorForm>
            </React.Fragment>
        );
    }
}

SingleAssignment.propTypes = propTypes;
SingleAssignment.defaultProps = defaultProps;

const mapStateToProps = (state) => {
    const assignment = state.workAssignment.assignmentTemp;
    const contactPersons = state.user.contactPersons || [];
    const profile = state.user.profile || [];
    const skills = state.skill.skills || [];
    const languages = skills.map((item, i) => ({
        value: item.SkillIdentifier,
        name: item.SkillName,
    }));
    return {
        assignment,
        skills,
        contactPersons,
        profile,
        languages,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSubmitAssignment: (state) => {
        dispatch(onUpdateNewAssignmentsTemp(state.assignment));
    },
    executeGetContactPersons: () => {
        dispatch(fetchContactPersons());
    },
    executeGetServices: (params, callback) => {
        dispatch(fetchServices(params, callback));
    },
    onCreateNewOrder: (assignment) => {
        dispatch(createNewOrder(assignment));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleAssignment);
