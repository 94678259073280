import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import {
  Translate,
  TransvoiceButton,
  ReasonList,
  Modal,
  DropdownList,
  ToggleSwitch,
  TextField,
} from '../../../Share/components';
import { getReasonList, cancelOrder, fetchServices } from '../../actions';
import './style.scss';
import { property } from 'lodash';
import { isIE } from '../../../Share/utils';
import { ValidatorForm } from 'react-form-validator-core';
import moment from 'moment';

const propTypes = {
  reasonList: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  onGetReasonList: PropTypes.func,
  assignmentDetail: PropTypes.shape({}),
  onCancelAssignment: PropTypes.func,
  onClose: PropTypes.func,
  isVisble: property.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      assignmentDetail: PropTypes.shape({}),
    }),
  }),
  onGetServices: PropTypes.func,
};

const defaultProps = {
  reasonList: [],
  services: [],
  onGetReasonList: () => {},
  onClose: () => {},
  isVisble: true,
  assignmentDetail: {},
  onCancelAssignment: () => {},
  location: {
    state: {
      assignmentDetail: {},
    },
  },
  onGetServices: () => {},
};

const INITAL_STATES = {
  showModal: false,
  reasonChoose: '',
  hasReasons: false,
  isToggleChecked: false,
  showAdditionalService: false,
  serviceTypesUpdated: false,
  additionalServiceDetails: null,
  clientTelephoneNo: null,
};

class CancelAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITAL_STATES };
  }
  componentDidMount() {
    this.executeGetReasonList();
    this.executeGetServices();
  }

  componentDidUpdate(prevProps, prevState) {
    const { services, assignmentDetail } = this.props;

    const { serviceTypesUpdated } = this.state;

    if (prevState.serviceTypesUpdated !== serviceTypesUpdated) {
      if (services) {
        const selectedService = services.find(
          service =>
            service.ServiceIdentifier === assignmentDetail.ServiceIdentifier,
        );

        if (selectedService) {
          if (
            selectedService.AdditionalServices &&
            Array.isArray(selectedService.AdditionalServices)
          ) {
            if (assignmentDetail.CustomerOrderAdditionalServices) {
              selectedService.AdditionalServices.find(service => {
                if (
                  service.AdditionalServiceIdentifier ===
                  assignmentDetail.CustomerOrderAdditionalServices[0]
                    .AdditionalServiceIdentifier
                ) {
                  if (service.IsSelectableForCancelOrder === true) {
                    this.setState({
                      showAdditionalService: true,
                      isToggleChecked: true,
                      additionalServiceDetails: {
                        AdditionalServiceIdentifier:
                          assignmentDetail.CustomerOrderAdditionalServices[0]
                            .AdditionalServiceIdentifier,
                        SendHoursAfterBookingInsertOrChange:
                          assignmentDetail.CustomerOrderAdditionalServices[0]
                            .SendHoursAfterBookingInsertOrChange,
                        SendHoursBeforeBooking:
                          assignmentDetail.CustomerOrderAdditionalServices[0]
                            .SendHoursBeforeBooking,
                      },
                      clientTelephoneNo:
                        assignmentDetail.CustomerOrderAdditionalServices[0]
                          .MessageReceiver,
                    });
                  } else {
                    this.setState({
                      additionalServiceDetails: null,
                      clientTelephoneNo: null,
                      showAdditionalService: false,
                    });
                  }
                }
              });
            } else {
              selectedService.AdditionalServices.find(service => {
                if (service.AdditionalServiceSetting.IsPreselected === true) {
                  this.setState({
                    additionalServiceDetails: {
                      AdditionalServiceIdentifier:
                        service.AdditionalServiceIdentifier,
                      SendHoursAfterBookingInsertOrChange:
                        service.AdditionalServiceSetting
                          .SendHoursAfterBookingInsertOrChangeDefault,
                      SendHoursBeforeBooking:
                        service.AdditionalServiceSetting
                          .SendHoursBeforeBookingDefault,
                    },
                  });
                  if (service.IsSelectableForCancelOrder === true) {
                    this.setState({
                      showAdditionalService: true,
                    });
                  }
                  return true;
                } else {
                  this.setState({
                    additionalServiceDetails: null,
                    showAdditionalService: false,
                  });
                }
              });
            }
          }
        }
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.reasonList.length > 0) {
      return {
        hasReasons: true,
      };
    }
    return null;
  }

  executeGetReasonList = () => {
    const { onGetReasonList } = this.props;
    onGetReasonList();
  };

  executeGetServices = () => {
    const { onGetServices, assignmentDetail } = this.props;
    const params = {
      language: assignmentDetail.SkillIdentifier,
      startTime: moment(assignmentDetail.DatetimeFrom).format(
        'YYYY-MM-DD HH:mm:ss',
      ),
      endTime: moment(assignmentDetail.DatetimeTo).format(
        'YYYY-MM-DD HH:mm:ss',
      ),
    };

    const callbackUpdateServices = services => {
      if (services && services.length > 0) {
        this.setState({
          serviceTypesUpdated: true,
        });
      }
    };

    onGetServices(params, callbackUpdateServices);
  };

  executeCancelAssignment = OrderIdentifier => {
    const { reasonList } = this.props;
    const { reasonChoose, clientTelephoneNo, additionalServiceDetails } =
      this.state;

    const { onCancelAssignment } = this.props;
    const customerOrderAdditionalServices =
      clientTelephoneNo && additionalServiceDetails
        ? [
            {
              AdditionalServiceIdentifier:
                additionalServiceDetails.AdditionalServiceIdentifier,
              MessageReceiver: clientTelephoneNo,
              SendHoursAfterBookingInsertOrChange:
                additionalServiceDetails.SendHoursAfterBookingInsertOrChange,
              SendHoursBeforeBooking:
                additionalServiceDetails.SendHoursBeforeBooking,
            },
          ]
        : null;

    onCancelAssignment(
      reasonChoose === '' ? reasonList[1].identifier : reasonChoose.identifier,
      OrderIdentifier,
      customerOrderAdditionalServices,
    );
  };

  handleShowModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleHideModal = () => {
    const { onClose } = this.props;
    onClose();
  };

  saveReason = e => {
    const { reasonList } = this.props;
    const selectedReason = reasonList.find(
      item => item.identifier === e.target.value,
    );
    this.setState({
      reasonChoose: selectedReason,
    });
  };

  onToggleSwitchChange = () => {
    const { isToggleChecked } = this.state;
    this.setState({
      isToggleChecked: !isToggleChecked,
      clientTelephoneNo: null,
    });
  };

  handleChange = event => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      clientTelephoneNo: value,
    });
  };

  render() {
    const { reasonList, isVisble, onClose, assignmentDetail } = this.props;
    const {
      hasReasons,
      reasonChoose,
      showModal,
      isToggleChecked,
      showAdditionalService,
      clientTelephoneNo,
    } = this.state;

    const getValuefromList = (filterText, list) => {
      if (filterText == null) return null;
      const filteredItems = list.filter(x => x.identifier === filterText);
      return filteredItems
        ? {
            name: filteredItems[0].content,
            value: filteredItems[0].identifier,
          }
        : null;
    };

    return (
      <React.Fragment>
        <Modal
          className="tv-cancelassignment__modal"
          onCloseClicked={() => {
            onClose();
          }}
          isVisible={isVisble}
          ieOptionClassName={isIE ? 'ie-fix-height' : ''}
        >
          {assignmentDetail.CanCancelOrder && (
            <>
              {' '}
              <div className="tv-cancelassignment__container flex-column">
                <div className="tv-cancelassignment__title tv-display-flex flex-column">
                  <h3 className="mb-3">
                    <Translate content="cancelAssignment.heading" />
                  </h3>
                  <div className="tv-cancelassignment__title-text">
                    <Translate content="cancelAssignment.modal.cancelMessage" />
                  </div>
                  {showAdditionalService && (
                    <>
                      <div className="tv-cancelassignment__sms-conatiner">
                        <div className="tv-cancelassignment__sms-conatiner__text">
                          Skicka ett avboknings-sms till klienten att tiden är
                          avbokad.
                        </div>
                        <ToggleSwitch
                          isChecked={isToggleChecked}
                          onToggleSwitchChange={this.onToggleSwitchChange}
                        />
                      </div>
                      {isToggleChecked && (
                        <ValidatorForm>
                          <div className="form-group tv-cancelassignment__phone-box">
                            <label htmlFor="clientTelephoneNo">
                              <Translate content="singleAssignment.telephoneNo" />
                            </label>
                            <TextField
                              onChange={this.handleChange}
                              className="text-field-style ellipsis pl-3 py-1 tv-single-assign-form__control"
                              name="clientTelephoneNo"
                              maxLength={300}
                              value={clientTelephoneNo}
                              placeholder="070-1740605"
                            />
                          </div>
                        </ValidatorForm>
                      )}
                    </>
                  )}
                </div>
                {/* <div className="tv-cancelassignment__reasons">
                                    <div className="tv-cancelassignment__reasons-title">
                                        <ValidatorForm ref="form">
                                            <div className="form-row col-12 justify-content-between mx-0 w-100">
                                                <div className="form-group col-12 col-md-5 mb-2 px-0 mx-auto">
                                                    <label>
                                                        {" "}
                                                        <Translate content="cancelAssignment.reason" />
                                                    </label>
                                                    <DropdownList
                                                        className="ellipsis pl-3 py-1 tv-single-assign-form__control"
                                                        name="typeOfAssignment"
                                                        options={reasonList.map(
                                                            (item) => ({
                                                                name:
                                                                    item.content,
                                                                value:
                                                                    item.identifier,
                                                            })
                                                        )}
                                                        value={getValuefromList(
                                                            reasonChoose.identifier,
                                                            reasonList
                                                        )}
                                                        onChange={(e) => {
                                                            const eventClone = {
                                                                target: {
                                                                    name:
                                                                        e.target
                                                                            .name,
                                                                    value:
                                                                        e.target
                                                                            .value
                                                                            .value,
                                                                },
                                                            };
                                                            this.saveReason(
                                                                eventClone
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div> */}
                {/* <div className="tv-cancelassignment__buttons tv-display-flex"></div> */}
              </div>
              <div
                className="d-flex flex-column"
                style={{ paddingInline: '10px' }}
              >
                <div className="tv-workassignment__detail-buttons tv-display-flex">
                  <TransvoiceButton
                    type="secondary"
                    text={Translate({
                      content: 'cancelAssignment.confirm',
                    })}
                    onClick={() => {
                      this.executeCancelAssignment(
                        assignmentDetail.OrderIdentifier,
                      );
                      onClose();
                    }}
                  />
                  <TransvoiceButton
                    type="line-primary"
                    className="tv-buttons__element-link"
                    text={Translate({
                      content: 'cancelAssignment.goback',
                    })}
                    onClick={onClose}
                  />
                </div>
              </div>
            </>
          )}

          {!assignmentDetail.CanCancelOrder && (
            <>
              <div className="tv-cancelassignment__container flex-column tv-workassignment__modal-container">
                <div className="tv-cancelassignment__title tv-display-flex flex-column h-100">
                  <h3 className="mb-3">
                    <Translate content="cancelAssignment.unableToCancelHeading" />
                  </h3>
                  <div className="tv-cancelassignment__title-text">
                    <Translate content="cancelAssignment.modal.cantCancelMessage" />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="tv-workassignment__detail-buttons tv-display-flex justify-content-around">
                  <TransvoiceButton
                    type="line-primary"
                    onClick={onClose}
                    text={Translate({
                      content: 'cancelAssignment.close',
                    })}
                  />
                </div>
              </div>
            </>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

CancelAssignment.propTypes = propTypes;
CancelAssignment.defaultProps = defaultProps;

const mapStateToProps = state => {
  const { reasonList } = state.reason;
  const { services } = state.services;
  return {
    reasonList,
    services,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetReasonList: () => {
    dispatch(getReasonList());
  },
  onCancelAssignment: (
    reasons,
    workAssignmentId,
    customerOrderAdditionalServices,
  ) => {
    dispatch(
      cancelOrder(reasons, workAssignmentId, customerOrderAdditionalServices),
    );
  },
  onGetServices: (params, callback) => {
    dispatch(fetchServices(params, callback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelAssignment);
